import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';

import ButtonLoadingSpinner from '@/ui/components/ButtonLoadingSpinner';
import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';
import { EMPTY_STRING } from '@/utils/empty';

import type { FormEvent } from 'react';

export interface Props {
    input: {
        onChange: (event: FormEvent<HTMLInputElement>) => void;
        value?: string;
        defaultValue?: string;
    };
    placeholder: string;
    expanded?: boolean;
    wrapperClass?: string;
    iconColorClass?: string;
    loading?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
}

export const ExpandableSearch = ({
    input,
    placeholder,
    wrapperClass = '',
    loading = false,
    expanded = false,
    iconColorClass = 'text-gray-500 hover:bg-gray-100 hover:text-gray-800 transition-colors',
    onClose,
    onOpen,
}: Props) => {
    const { t } = useTranslation('common');
    const inputRef = useRef<HTMLInputElement>(null);
    const [isExpanded, setIsExpanded] = useState(expanded);

    useEffect(() => {
        setIsExpanded(expanded);
    }, [expanded]);

    const handleOpen = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }

        onOpen?.();

        setIsExpanded(true);
    };

    const handleClose = () => {
        if (inputRef.current) {
            inputRef.current.value = EMPTY_STRING;
            inputRef.current.blur();
        }

        onClose?.();

        setIsExpanded(false);
    };

    const wrapperClassName = cn('flex w-11 justify-end transition-all ease-out', wrapperClass, {
        'w-52 md:w-64': isExpanded,
    });

    const containerClassName = cn(
        'flex h-11 w-11 items-center overflow-hidden rounded-lg transition-all duration-200 ease-out',
        {
            'w-full bg-gray-100': isExpanded,
        },
    );

    return (
        <div className={wrapperClassName}>
            <Tooltip content={t('search')} placement="bottom" disabled={isExpanded}>
                <div className={containerClassName}>
                    <div
                        className={cn(
                            'flex h-full w-11 min-w-0 flex-shrink-0 cursor-pointer items-center justify-center',
                            isExpanded ? 'text-blue-500' : `min-w-full ${iconColorClass}`,
                        )}
                        onClick={handleOpen}
                    >
                        <MagnifyingGlassIcon className="size-6" />
                    </div>
                    <div className="flex min-w-0 flex-1">
                        <input
                            type="text"
                            className="w-full bg-transparent text-sm focus-visible:outline-none"
                            ref={inputRef}
                            placeholder={placeholder}
                            {...input}
                        />
                    </div>
                    {loading ? (
                        <div className="flex h-full w-11 min-w-0 flex-shrink-0 cursor-pointer items-center justify-center text-gray-400">
                            <ButtonLoadingSpinner className="text-gray-500" />
                        </div>
                    ) : (
                        <div
                            className="flex h-full w-11 min-w-0 flex-shrink-0 cursor-pointer items-center justify-center text-gray-500 transition-colors hover:text-gray-800"
                            onClick={handleClose}
                        >
                            <XMarkIcon className="size-5" />
                        </div>
                    )}
                </div>
            </Tooltip>
        </div>
    );
};

export default ExpandableSearch;
